import { fullBot } from "../Bots";
import { mapType } from "../../Models";

const testBot: fullBot = {
  id: "12345",
  imageSrc: "./image",
  location: "Berlin",
  name: "bot123",
  armour: "10",
  seats: "2",
  weapon: "laser",
  speed: "3",
  status: "online",
};

export const testBotArray: fullBot[] = [
  {
    id: "12345",
    imageSrc: "./image",
    location: "Berlin",
    name: "bot123",
    armour: "10",
    seats: "2",
    weapon: "laser",
    speed: "3",
    status: "online",
  },
  {
    id: "23456",
    imageSrc: "./image",
    location: "Berlin",
    name: "fighter",
    armour: "10",
    seats: "2",
    weapon: "laser",
    speed: "3",
    status: "online",
  },
  {
    id: "34567",
    imageSrc: "./image",
    location: "Berlin",
    name: "killa bot",
    armour: "10",
    seats: "2",
    weapon: "laser",
    speed: "3",
    status: "online",
  },
  {
    id: "45678",
    imageSrc: "./image",
    location: "Berlin",
    name: "brain",
    armour: "10",
    seats: "2",
    weapon: "laser",
    speed: "3",
    status: "online",
  },
  {
    id: "56789",
    imageSrc: "./image",
    location: "Berlin",
    name: "C33bo",
    armour: "10",
    seats: "2",
    weapon: "laser",
    speed: "3",
    status: "online",
  },
  {
    id: "678910",
    imageSrc: "./image",
    location: "Berlin",
    name: "My Giant",
    armour: "10",
    seats: "2",
    weapon: "laser",
    speed: "3",
    status: "online",
  },
  {
    id: "7891011",
    imageSrc: "./image",
    location: "Berlin",
    name: "Ironbot",
    armour: "10",
    seats: "2",
    weapon: "laser",
    speed: "3",
    status: "online",
  },
  {
    id: "89101112",
    imageSrc: "./image",
    location: "Berlin",
    name: "robocop",
    armour: "10",
    seats: "2",
    weapon: "laser",
    speed: "3",
    status: "online",
  },
  {
    id: "910111213",
    imageSrc: "./image",
    location: "Berlin",
    name: "johnny 5",
    armour: "10",
    seats: "2",
    weapon: "laser",
    speed: "3",
    status: "online",
  },
  {
    id: "101112131415",
    imageSrc: "./image",
    location: "Berlin",
    name: "wallee",
    armour: "10",
    seats: "2",
    weapon: "laser",
    speed: "3",
    status: "online",
  },
];
