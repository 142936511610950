/* eslint-disable no-underscore-dangle */
/* global XMLSerializer */
import Cancellation, { CancellationError } from 'cancel';
import eventing from './eventing';
import createLogger from './log';
import eventHelper from './eventHelper';
import defaultEnv from './env';
import ensureCSSUnit from './widget_view/ensureCSSUnit';
import hasPassiveCapability from './hasPassiveCapability';
import DefaultOTHelpers from '../common-js-helpers/OTHelpers';
import DefaultVideoElementFacadeFactory from './video_element';
import createWidgetInitialsSvg from './createWidgetInitialsSvg';
import getOrCreateContainer from './widget_view/getOrCreateContainer';
import fixMini from './widget_view/fixMini';
import defaultWaitForVideoToBePlaying from './waitForVideoToBePlaying';
import waitForVideoResolution from './waitForVideoResolution';
import unblockAudio from '../ot/unblockAudio';

const defaultLogging = createLogger('WidgetView');

// eslint-disable-next-line no-unused-vars
const typeVideoElementFacade = DefaultVideoElementFacadeFactory();

/**
 * WidgetViewFactory - DI factory for WidgetView
 *
 * @package
 * @param {Object} deps
 * @param {DefaultVideoElementFacadeFactory} deps.VideoElementFacade
 * @return WidgetView
 */
function WidgetViewFactory({
  logging = defaultLogging,
  OTHelpers = DefaultOTHelpers,
  waitForVideoToBePlaying = defaultWaitForVideoToBePlaying,
  VideoElementFacade = DefaultVideoElementFacadeFactory(),
  env = defaultEnv,
} = {}) {
  // We pass in the real window rather than a mock because hasPassiveCapability
  // needs to attach/remove real event listeners on the window which won't work
  // using the mock window.
  const supportsPassive = hasPassiveCapability({ window: global });

  const throwIfBindCancelled = (cancellation) => {
    if (cancellation.isCanceled()) {
      logging.debug('bindVideo bailing due to cancellation');
      throw new CancellationError('CANCEL');
    }
  };

  /**
   * WidgetView - A standard abstraction used by Subscriber and Publisher for
   * displaying the video and it's UI.
   *
   * @package
   * @class
   * @param {Element} targetElement the element to attach this WidgetView to
   * @param {Object} properties
   */
  class WidgetView {
    /** @type {typeVideoElementFacade} */
    _videoElementFacade;
    /** @type {HTMLElement} */
    _container = undefined;

    _posterContainer = document.createElement('div');
    _widgetContainer = document.createElement('div');
    _loading = true;
    _audioOnly = false;
    _showPoster = undefined;
    _poster = undefined;

    _cancelBind = undefined;

    constructor(
      /** @type {HTMLElement} */
      targetElement,
      {
        insertDefaultUI = true,
        width = 264,
        height = 198,
        fitMode = 'cover',
        mirror = false,
        insertMode,
        classNames,
        style,
        widgetType,
        initials,
      } = {}
    ) {
      eventing(this);

      if (/^(contain|cover)$/.test(fitMode) === false) {
        logging.warn(`Invalid fit value "${fitMode}" passed. Only "contain" and "cover" can be used.`);
      }

      this._widgetType = widgetType;
      this._fitMode = fitMode;
      this._insertDefaultUI = insertDefaultUI;

      const userInteractionEventHandlers = eventHelper(this._widgetContainer);
      userInteractionEventHandlers.on('click', this.userGesture.bind(this));
      userInteractionEventHandlers.on('touchstart', this.userGesture.bind(this), supportsPassive ? { passive: true } : false);

      this.once('destroy', () => userInteractionEventHandlers.removeAll());

      this._widgetContainer.classList.add('OT_widget-container');

      this._widgetContainer.style.width = '100%'; // container.style.width;
      this._widgetContainer.style.height = '100%'; // container.style.height;

      if (insertDefaultUI !== false) {
        this._container = getOrCreateContainer(targetElement, insertMode);
        if (env.isSafari && env.isiOS && env.iOSVersion && env.iOSVersion < 11.2) {
          this._container.classList.add('OT_ForceContain');
        }
        this._container.style.width = ensureCSSUnit(width);
        this._container.style.height = ensureCSSUnit(height);
        this._container.style.overflow = 'hidden';
        fixMini(this._container);

        if (mirror) {
          OTHelpers.addClass(this._container, 'OT_mirrored');
        }

        if (classNames) {
          // @todo Refactor to avoid passing classNames to widgetView
          classNames.trim().split(/\s+/).forEach(className => this._container.classList.add(className));
        }

        this._container.classList.add('OT_loading');
        this._container.classList.add(`OT_fit-mode-${fitMode}`);
        this._container.appendChild(this._widgetContainer);

        // Observe changes to the width and height and update the aspect ratio
        const [sizeObserver] = OTHelpers(this._container).observeSize(
          () => fixMini(this._container)
        );

        // @todo observe if the video container or the video element get removed
        // if they do we should do some cleanup
        const videoObserver = OTHelpers.observeNodeOrChildNodeRemoval(
          this._container,
          (removedNodes) => {
            if (!this._videoElementFacade) {
              return;
            }
            // check if our widget container / video element was removed
            const videoRemoved = removedNodes.some(
              node => node === this._widgetContainer ||
                node === this._videoElementFacade.domElement()
            );
            if (videoRemoved) {
              this.destroyVideo();
            }
          }
        );

        this.once('destroy', () => {
          logging.debug('disconnecting observers');
          sizeObserver.disconnect();
          videoObserver.disconnect();
        });
      }

      this._posterContainer.classList.add('OT_video-poster');
      this._widgetContainer.appendChild(this._posterContainer);

      const loadingContainer = document.createElement('div');
      loadingContainer.classList.add('OT_video-loading');
      const loadingSpinner = document.createElement('div');
      loadingSpinner.classList.add('OT_video-loading-spinner');
      loadingContainer.appendChild(loadingSpinner);
      this._widgetContainer.appendChild(loadingContainer);

      const hasBackgroundImageURI = style && style.backgroundImageURI;
      // Precedence for background is: background image, then initials.
      // If background image is not present, use initials.
      if (hasBackgroundImageURI) {
        this.setBackgroundImageURI(style.backgroundImageURI);
      } else if (initials) {
        this.setInitials(initials);
      }
    }
    get domElement() {
      return this._container;
    }
    addError(errorMsg, helpMsg, classNames) {
      if (this._container) {
        this._container.innerHTML = `<p>${errorMsg}${helpMsg ? ` <span class="ot-help-message">${helpMsg}</span>` : ''}</p>`;
        OTHelpers.addClass(this._container, classNames || 'OT_subscriber_error');
        if (this._container.querySelector('p').offsetHeight > this._container.offsetHeight) {
          this._container.querySelector('span').style.display = 'none';
        }
      }
    }
    /**
     * Destroy the video
     */
    destroy() {
      this.emit('destroy');

      this.destroyVideo();
      if (this._container) {
        OTHelpers.removeElement(this._container);
        this._container = null;
      }
    }
    setBackgroundImageURI(bgImgURI) {
      // If initials are set, this should override.
      OTHelpers.css(this._posterContainer, 'backgroundImage', `url(${bgImgURI})`);
      OTHelpers.css(this._posterContainer, 'backgroundSize', 'contain');
      OTHelpers.css(this._posterContainer, 'opacity', '1.0');
    }
    setInitials(initials) {
      const initialsSvg = createWidgetInitialsSvg(initials);
      const serializedSvg = new XMLSerializer().serializeToString(initialsSvg);
      OTHelpers.css(this._posterContainer, 'backgroundImage', `url('data:image/svg+xml,${serializedSvg}')`);
      OTHelpers.css(this._posterContainer, 'backgroundSize', 'auto 55%');
      OTHelpers.css(this._posterContainer, 'opacity', '1.0');
    }
    isAudioBlocked() {
      return Boolean(
        this._videoElementFacade &&
        this._videoElementFacade.isAudioBlocked()
      );
    }
    unblockAudio() {
      return this._videoElementFacade.unblockAudio();
    }
    userGesture() {
      if (this.isAudioBlocked()) {
        unblockAudio().then(
          () => logging.debug('Successfully unblocked audio'),
          err => logging.error('Error retrying audio on user interaction:', err)
        );
      }
    }
    setAudioBlockedUi(audioBlocked) {
      if (!this._container) {
        return;
      }
      if (audioBlocked) {
        this._container.classList.add('OT_container-audio-blocked');
      } else {
        this._container.classList.remove('OT_container-audio-blocked');
      }
    }
    rebindSrcObject() {
      if (this._videoElementFacade) {
        this._videoElementFacade._rebindSrcObject();
      }
    }
    pauseAndPlayVideoElement() {
      if (this._videoElementFacade) {
        this._videoElementFacade._pauseAndPlay();
      }
    }
    MAX_VIDEO_LOADED = 5 * 1000;
    // BufferVideoElement will act as pre-buffer to asure that we can play actual video/audio
    // before the bind. For that we create a plain videoElement, add the stream, play it and
    // wait to get the media flowing. We return the videoElement so it can be clean up once used.
    // In case of failure we return null.
    async _createBufferVideoElement(stream, deps = {}) {
      const documentHelper = deps.createElement ? deps : document;
      let isVideoLoaded = false;
      const videoElement = documentHelper.createElement('video');
      videoElement.srcObject = stream;
      try {
        videoElement.play();
        // Wait for the video to be loaded and playing for MAX_VIDEO_LOADED secs.
        isVideoLoaded = await waitForVideoToBePlaying(videoElement, this.MAX_VIDEO_LOADED);
        // If media has flowed, return videoElement. Otherwise, return null since buffer has failed.
        return isVideoLoaded ? videoElement : null;
      } catch (error) {
        // We are not interested in the error, we just need to know that play() fails.
        return null;
      }
    }
    _emitAMREvent(action, variation, options) {
      this.emit('amrLogEvent', action, variation, options);
    }
    _createVideoElementFacade({ _inject, audioVolume, fallbackText, muted }) {
      this._videoElementFacade = new VideoElementFacade({
        defaultAudioVolume: parseFloat(audioVolume),
        fallbackText,
        fitMode: this._fitMode,
        _inject,
        muted,
        widgetType: this._widgetType,
      });

      const videoFacadeEvents = eventHelper(this._videoElementFacade);

      this._videoFacadeEvents = videoFacadeEvents;

      videoFacadeEvents.on('error', () => {
        this.trigger('error');
      });

      videoFacadeEvents.on('videoDimensionsChanged', (oldValue, newValue) => {
        this.trigger('videoDimensionsChanged', oldValue, newValue);
      });

      videoFacadeEvents.on('mediaStopped', (track) => {
        this.trigger('mediaStopped', track);
      });

      videoFacadeEvents.on('audioBlocked', () => this.trigger('audioBlocked'));
      videoFacadeEvents.on('audioUnblocked', () => this.trigger('audioUnblocked'));

      // makes the incoming audio streams take priority (will impact only FF OS for now)
      this._videoElementFacade.audioChannelType('telephony');

      logging.debug('Appending the video facade');
      this._videoElementFacade.appendTo(this._widgetContainer);

      // Initialize the audio volume
      if (typeof audioVolume !== 'undefined') {
        try {
          this._videoElementFacade.setAudioVolume(audioVolume);
        } catch (e) {
          logging.warn(`bindVideo ${e}`);
        }
      }
    }
    async _bindToStream(webRTCStream, cancellation) {
      try {
        await this._videoElementFacade.bindToStream(webRTCStream);
      } catch (err) {
        if (cancellation.isCanceled()) {
          logging.debug('Refusing to destroyVideo as bindVideo was cancelled');
          throw new CancellationError('CANCEL');
        } else {
          this.destroyVideo();
          throw err;
        }
      }
    }
    _getNewBindCancellation() {
      if (this._cancelBind) {
        logging.debug('Cancelling last bindVideo request');
        this._cancelBind.cancel();
      }

      const cancellation = new Cancellation();
      this._cancelBind = cancellation;

      this.once('destroy', () => cancellation.cancel());
      return cancellation;
    }
    _triggerVideoElementCreated() {
      const triggerVideoElementCreated = (element) => {
        this.trigger('videoElementCreated', element);
        if (this._insertDefaultUI) {
          OTHelpers.addClass(element, 'OT_video-element');
        }
      };

      if (this._videoElementFacade.domElement()) {
        triggerVideoElementCreated(this._videoElementFacade.domElement());
      } else {
        this._videoFacadeEvents.on('videoElementCreated', (element) => {
          triggerVideoElementCreated(element);
        });
      }
    }
    async _waitForVideoResolution(webRTCStream) {
      // We do not need to wait for video resolution when audio only or no video tracks.
      if (!this.audioOnly() && webRTCStream.getVideoTracks().length > 0) {
        logging.debug('Waiting for correct resolution');
        await waitForVideoResolution(this._videoElementFacade, 5000);
        logging.debug(`Resolution: ${this._videoElementFacade.videoWidth()}x${this._videoElementFacade.videoHeight()}`);
      }
    }
    /**
     * Bind a video to a VideoElementFacade
     *
     * @param {MediaStream} webRTCStream the MediaStream to bind
     * @param {Object} options
     * @param {Function} options.error Error callback
     * @param {Float} options.audioVolume The initial audioVolume
     * @param {Boolean} options.muted The initial mute state
     * @param {String} [options.fallbackText] The default fallbackText
     *
     * @return {Promise<undefined>}
     */
    async bindVideo(webRTCStream, { audioVolume, muted, fallbackText, _inject },
      throwIfBufferFails = false) {
      logging.debug('bindVideo ', { webRTCStream });

      const hasExistingElement = !!this._videoElementFacade;
      let bufferVideoElement;

      const cancellation = this._getNewBindCancellation();
      if (!hasExistingElement) {
        this._createVideoElementFacade({ _inject, audioVolume, fallbackText, muted });
      } else if (webRTCStream instanceof MediaStream) { // eslint-disable-line no-undef
        // Temporary logging to monitor the blink: OPENTOK-46931
        // All the logging is done at Subscriber level, we just emit actions
        // to be potentially logged
        this._emitAMREvent('AMRLoadVideoBuffer', 'Attempt');

        // For transitions, before binding we use the bufferVideoElement to make sure video
        // from webRTCStream is loaded and playing.
        bufferVideoElement = await this._createBufferVideoElement(webRTCStream);
        if (!bufferVideoElement) {
          this._emitAMREvent('AMRLoadVideoBuffer', 'Failure');
          if (throwIfBufferFails) {
            throw new Error('bufferFailed: videoBuffer failed to load or timeout');
          }
        } else {
          this._emitAMREvent('AMRLoadVideoBuffer', 'Success');
        }
        // We now want to log binding duration to monitor the blink: OPENTOK-46931
        // We propagate the event from videoFacade level to Subscriber
        this._videoFacadeEvents.on('amrLogEvent', (action, variation, options) => {
          this._emitAMREvent(action, variation, options);
        });
      }

      await this._bindToStream(webRTCStream, cancellation);

      if (!hasExistingElement) {
        this._triggerVideoElementCreated();
      }

      throwIfBindCancelled(cancellation);

      const whenVideoPlaying = waitForVideoToBePlaying(this._videoElementFacade, 5000);

      await this._waitForVideoResolution(webRTCStream);

      logging.debug('Waiting for video to be playing');
      await whenVideoPlaying;
      logging.debug('Video is playing');

      throwIfBindCancelled(cancellation);

      if (bufferVideoElement) {
        // We do not need the helper anymore, so we clean it up.
        if (bufferVideoElement.remove) {
          bufferVideoElement.remove();
        }
        bufferVideoElement = null;
      }
    }
    bindAudioTrackOnly() {
      if (this._videoElementFacade) {
        this._videoElementFacade.bindAudioTrackOnly();
      }
    }
    destroyVideo() {
      if (this._videoElementFacade) {
        this._videoElementFacade.destroy();
        this._videoElementFacade = null;
      }
    }
    video() {
      return this._videoElementFacade;
    }
    showPoster(showPoster) {
      if (showPoster === undefined) {
        return !OTHelpers.isDisplayNone(this._posterContainer);
      }
      this._showPoster = showPoster;
      OTHelpers[showPoster ? 'show' : 'hide'](this._posterContainer);
      return this.showPoster();
    }
    poster(src) {
      if (src === undefined) {
        return OTHelpers.css(this._posterContainer, 'backgroundImage');
      }
      this._poster = src;
      OTHelpers.css(this._posterContainer, 'backgroundImage', `url(${src})`);
      return this.poster();
    }
    loading(isLoading) {
      if (isLoading === undefined) {
        return this._loading;
      }
      this._loading = Boolean(isLoading);
      if (this._container) {
        this._container.classList[isLoading ? 'add' : 'remove']('OT_loading');
      }
      return this.loading();
    }
    audioOnly(isAudioOnly) {
      if (isAudioOnly === undefined) {
        return this._audioOnly;
      }
      this._audioOnly = isAudioOnly;
      if (this._container) {
        this._container.classList[isAudioOnly ? 'add' : 'remove']('OT_audio-only');
      }
      return this.audioOnly();
    }
    domId() {
      return this._container && this._container.getAttribute('id');
    }
    setSinkId(deviceId) {
      if (this._videoElementFacade) {
        return this._videoElementFacade.setSinkId(deviceId);
      }
      return undefined;
    }
    /** @return {HTMLVideoElement} */
    get videoElement() {
      return (this._videoElementFacade && this._videoElementFacade.domElement()) || undefined;
    }
    /**
     * The width of the video element in pixels
     * @return {Number}
     */
    get width() {
      return this.videoElement && this.videoElement.offsetWidth;
    }
    /**
     * The height of the video element in pixels
     * @return {Number}
     */
    get height() {
      return this.videoElement && this.videoElement.offsetHeight;
    }
  }

  return WidgetView;
}

export default WidgetViewFactory;
