const getPacketStats = (stats, mediaKind) => {
  const outboundRtpDataStats = stats.filter(({ type, kind }) => type === 'outbound-rtp' && kind === mediaKind);
  const remoteInboundRtpDataStats = stats.filter(({ type, kind }) => type === 'remote-inbound-rtp' && kind === mediaKind);

  // Sum total packets from all rtp stats, could be up to 3 video streams with simulcast
  const packetsSent = outboundRtpDataStats.reduce((acc, data) => acc + data.packetsSent, 0);
  const packetsLost = remoteInboundRtpDataStats.reduce((acc, data) => acc + data.packetsLost, 0);

  return { packetsSent, packetsLost };
};

export const getVideoResolution = (stats) => {
  const outboundRtpDataStats = stats
    .filter(({ type, kind }) => type === 'outbound-rtp' && kind === 'video')
    .filter(stat => stat.frameHeight !== undefined && stat.frameWidth !== undefined);

  // get largest resolution of simulcast layers
  const resolution = outboundRtpDataStats.reduce((acc, data) => ({
    width: Math.max(acc.width, data.frameWidth),
    height: Math.max(acc.height, data.frameHeight),
  }), {
    width: 0,
    height: 0,
  });

  return resolution;
};

export const getAudioPacketStats = (stats) => {
  const { packetsSent, packetsLost } = getPacketStats(stats, 'audio');
  return { audioPacketsLost: packetsLost, audioPacketsSent: packetsSent };
};

export const getVideoPacketStats = (stats) => {
  const { packetsSent, packetsLost } = getPacketStats(stats, 'video');
  return { videoPacketsLost: packetsLost, videoPacketsSent: packetsSent };
};

export const getSelectedCandidatePairId = (stats) => {
  const transportStats = stats.find(({ type }) => type === 'transport');
  return transportStats?.selectedCandidatePairId;
};

const getBandwidthForCandidatePairId = (stats, selectedCandidatePairId) => { // eslint-disable-line consistent-return
  if (selectedCandidatePairId) {
    const candidatePairData = stats.find(({ id, type }) => id === selectedCandidatePairId && type === 'candidate-pair');
    return candidatePairData?.availableOutgoingBitrate;
  }
};

export const getBandwidth = (stats) => {
  const bandwidth = getBandwidthForCandidatePairId(stats, getSelectedCandidatePairId(stats));
  if (bandwidth !== undefined) {
    return bandwidth;
  }
  const candidatePairData = stats.find(({ type, availableOutgoingBitrate }) => (type === 'candidate-pair' && availableOutgoingBitrate !== undefined));
  return candidatePairData?.availableOutgoingBitrate;
};

export const parseStats = (stats) => {
  const { audioPacketsSent, audioPacketsLost } = getAudioPacketStats(stats);
  const { videoPacketsSent, videoPacketsLost } = getVideoPacketStats(stats);
  const bandwidth = getBandwidth(stats);
  const videoResolution = getVideoResolution(stats);
  return {
    bandwidth,
    audioPacketsSent,
    audioPacketsLost,
    videoPacketsSent,
    videoPacketsLost,
    videoResolution,
  };
};

const safeDivide = (num, den) => {
  if (den === 0) {
    return 0;
  }
  return num / den;
};

const getPacketLoss = (type, currentParsedStats, prevParsedStats) => {
  const packetsSentDelta = currentParsedStats[`${type}PacketsSent`] - prevParsedStats[`${type}PacketsSent`];
  const packetsLostDelta = currentParsedStats[`${type}PacketsLost`] - prevParsedStats[`${type}PacketsLost`];
  return safeDivide(packetsLostDelta, packetsSentDelta);
};

export const getAudioPacketLoss = (currentParsedStats, prevParsedStats) => getPacketLoss('audio', currentParsedStats, prevParsedStats);
export const getVideoPacketLoss = (currentParsedStats, prevParsedStats) => getPacketLoss('video', currentParsedStats, prevParsedStats);
