import getShouldUseStandardGetStats from '../../helpers/shouldUseStandardGetStats';

const shouldUseStandardGetStats = getShouldUseStandardGetStats();

const getRtcStats = (peerConnection, completion) => {
  peerConnection.getStats(null)
    .then(stats => completion(null, stats))
    .catch(completion);
};

const getRtcStatsLegacy = (peerConnection, completion) => {
  peerConnection.getStats((stats) => {
    completion(null, stats);
  },
  completion);
};

// This returns the native or "raw" RtcStatsReport object
const getRtcStatsReportAdapter = (peerConnection, completion) => {
  const getRtcStatsReport = shouldUseStandardGetStats ?
    getRtcStats : getRtcStatsLegacy;
  getRtcStatsReport(peerConnection, completion);
};

export default getRtcStatsReportAdapter;
