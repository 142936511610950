/* eslint-disable no-underscore-dangle */
import isEqual from 'lodash/isEqual';
import EventEmitter from 'events';
import createTimeBasedCongestionLevelDefault from './congestionLevelCalculators/timeBasedCongestionLevel';
import createBasicCongestionLevelDefault from './congestionLevelCalculators/basicCongestionLevel';
import createCongestionLevelStatsDefault from './congestionLevelStats';
import createMovingAverageTrackerDefault from './exponentialMovingAverageTracker';
import getFairQualityBandwidthForResolutionDefault from './getFairQualityBandwidthForResolution';

const createCongestionLevelEstimator = (opts = {}, deps = {}) => {
  const ee = new EventEmitter();
  const { getStats } = opts;
  const {
    createMovingAverageTracker = createMovingAverageTrackerDefault,
    createTimeBasedCongestionLevel = createTimeBasedCongestionLevelDefault,
    createBasicCongestionLevel = createBasicCongestionLevelDefault,
    createCongestionLevelStats = createCongestionLevelStatsDefault,
    getFairQualityBandwidthForResolution = getFairQualityBandwidthForResolutionDefault,
  } = deps;

  const congestionLevelTimeBased = createTimeBasedCongestionLevel();
  const congestionLevelBasic = createBasicCongestionLevel();
  const audioPacketLossStats = createMovingAverageTracker();
  const videoPacketLossStats = createMovingAverageTracker();
  const bandwidthStats = createMovingAverageTracker();
  let resolution;

  const getTimeBasedCongestionLevel = () => congestionLevelTimeBased.getLevel({
    bandwidth: bandwidthStats.getMovingAverageValue(),
    audioPacketLoss: audioPacketLossStats.getMovingAverageValue(),
  });

  const getBasicCongestionLevel = () => congestionLevelBasic.getLevel({
    bandwidth: bandwidthStats.getMovingAverageValue(),
    audioPacketLoss: audioPacketLossStats.getMovingAverageValue(),
    bandwidthFairThreshold: getFairQualityBandwidthForResolution(resolution),
  });

  const getCongestionLevel = () => {
    const timeLevel = getTimeBasedCongestionLevel();
    const basicLevel = getBasicCongestionLevel();
    const congestionLevel = Math.max(
      timeLevel, basicLevel
    );
    ee.emit('congestionLevel', congestionLevel);
  };

  const onStatsAvailable = ({ audioPacketLoss, videoPacketLoss, bandwidth, videoResolution }) => {
    if (resolution && !isEqual(resolution, videoResolution)) {
      audioPacketLossStats.reset();
      videoPacketLossStats.reset();
      bandwidthStats.reset();
    }
    resolution = videoResolution;
    audioPacketLossStats.addValue(audioPacketLoss);
    videoPacketLossStats.addValue(videoPacketLoss);
    bandwidthStats.addValue(bandwidth);
    getCongestionLevel();
  };

  const congestionLevelStats = createCongestionLevelStats(getStats);
  congestionLevelStats.on('statsAvailable', (stats) => {
    onStatsAvailable(stats);
  });

  return Object.assign(ee, {
    start() {
      congestionLevelStats.start();
    },
    stop() {
      congestionLevelStats.stop();
    },
  });
};

export default createCongestionLevelEstimator;
