import DefaultParameters from './defaultParameters';
import { CRITICAL, MEDIUM, LOW } from '../congestionLevels';
import createLogger from '../../../../helpers/log';

const logging = createLogger('pafdebug:BasicConnectionLevel');

const congestionLevelString = ['LOW', 'MEDIUM', 'CRITICAL'];

const createCongestionLevelBasic = (customParams = {}) => {
  const params = {
    ...DefaultParameters,
    ...customParams,
  };
  return {
    getLevel({ bandwidth, audioPacketLoss, bandwidthFairThreshold }) {
      let congestionLevel;

      const logResult = branch =>
        logging.info(`${congestionLevelString[congestionLevel]} Branch: ${branch}`, { bandwidth, audioPacketLoss, bandwidthFairThreshold, params });

      if (bandwidth <= 0) {
        if (audioPacketLoss > params.packetLossCriticalThresholdMediumBw) {
          congestionLevel = CRITICAL;
          logResult('audioPacketLoss > params.packetLossCriticalThresholdMediumBw');
        } else {
          congestionLevel = LOW;
        }

        return congestionLevel;
      }

      if (bandwidth >= bandwidthFairThreshold) { // LOW CONGESTION ZONE
        congestionLevel = LOW;
      } else if (bandwidth >= params.bandwidthCriticalThreshold) {
      // MEDIUM BW ZONE WITH DEPENDENCY WITH PL
        if (
          bandwidth >= ((params.bandwidthCriticalThreshold + bandwidthFairThreshold) / 2.0)
        ) {
          // IF IN THE HIGHER MID-BAND WITH MEDIUM BW
          if (audioPacketLoss > params.packetLossCriticalThresholdMediumBw * 2.0) {
            congestionLevel = CRITICAL;
            logResult('audioPacketLoss > params.packetLossCriticalThresholdMediumBw * 2.0');
          } else {
            congestionLevel = MEDIUM;
            logResult('!audioPacketLoss > params.packetLossCriticalThresholdMediumBw * 2.0');
          }
        } else if (audioPacketLoss > params.packetLossCriticalThresholdMediumBw) {
          // IF IN THE LOWER MID-BAND WITH MEDIUM BW
          congestionLevel = CRITICAL;
          logResult('audioPacketLoss > params.packetLossCriticalThresholdMediumBw');
        } else {
          congestionLevel = MEDIUM;
          logResult('audioPacketLoss > params.packetLossCriticalThresholdMediumBw');
        }
      } else if (audioPacketLoss > params.packetLossCriticalThreshold) {
        // CRITICAL BW ZONE WITH DEPENDENCY WITH PL
        congestionLevel = CRITICAL;
        logResult('audioPacketLoss > params.packetLossCriticalThreshold');
      } else {
        congestionLevel = MEDIUM;
        logResult('audioPacketLoss > params.packetLossCriticalThreshold');
      }

      if (congestionLevel === undefined) { throw new Error('undefined congestion level'); }
      return congestionLevel;
    },
  };
};

export default createCongestionLevelBasic;
