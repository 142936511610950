class SubscriberPeerConnectionQueue {
  constructor() {
    this.queue = [];
    this.isAddingSubscriber = false;
  }

  add(subscriberCreate) {
    if (this.isAddingSubscriber) {
      this.queue.push(subscriberCreate);
    } else {
      this.isAddingSubscriber = true;
      subscriberCreate();
    }
  }

  next() {
    const subscriberCreate = this.queue.shift();
    if (subscriberCreate) {
      subscriberCreate();
    } else {
      this.isAddingSubscriber = false;
    }
  }
}

export default SubscriberPeerConnectionQueue;
