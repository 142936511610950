/* eslint-disable no-underscore-dangle */
import eventing from '../../helpers/eventing';
import arrayRemoveItem from '../../helpers/arrayRemoveItem';
import PeerConnection from './peer_connection';
import SubscriberPeerConnectionQueue from './subscriberPeerConnectionQueue';
import SDPHelpers from './sdp_helpers';

const PeerConnectionDefault = PeerConnection();

class SinglePeerConnectionController {
  constructor(session, deps = {}) {
    this._session = session;
    this.PeerConnection = deps.PeerConnection || PeerConnectionDefault;
    this._subscriberCreateQueue = new SubscriberPeerConnectionQueue();
    this._originalSendMessages = {};
    this._tracks = [];
    this._subscriberPcs = {};
    this._firstSubscriberPc = null;
    this._sendMessage = () => {};
    eventing(this);
  }

  parseOptions(options) {
    return {
      ...options,
      // We always remove unused codecs after iceRestart in SPC
      removeUnusedCodecs: true,
      sendMessage: (type, payload) => this.trigger('sendMessage', type, payload),
    };
  }

  addTrackAddedListener() {
    if (!this._peerConnection) {
      return;
    }

    // override the trackAdded listener so we can route the tracks to the proper subscriber.
    this._peerConnection.on('trackAdded', (stream) => {
      const { track } = stream;
      if (this._tracks.includes(track.id)) {
        return;
      }
      // We store all tracks per Subscriber.
      this._subscriberPcs[this._subscriberPcToAdd._id].push(track.id);
      // Also store all tracks per SinglePeerConnection
      this._tracks.push(track.id);
      this._subscriberPcToAdd._onTrackAdded(stream);
    });
  }

  // Singleton to make sure we are using only one PC when SPC. If SPC, we will add Subscriber
  // specific options to handle analytics and negotiation per Subscriber. This will take care
  // to send the answer back to Rumor by its respective Subcriber and not multiple answers.
  // It will instantiate a new regular PC for all other cases.
  getPeerConnection(opt, subscriberPc) {
    this._subscriberPcToAdd = subscriberPc;
    this._sendMessage = opt.sendMessage;
    this._originalSendMessages[subscriberPc._id] = this._sendMessage;

    if (!this._firstSubscriberPc) {
      this._firstSubscriberPc = subscriberPc._id;
    }

    if (this._peerConnection) {
      this._peerConnection.addOptions(opt);
    } else {
      // Add listener for PeerConnection sendMessage, so we can properly send the message from
      // the PC to Rumor.
      this.on('sendMessage', (type, payload) => {
        this._sendMessage(type, payload);
      });

      const parsedOptions = this.parseOptions(opt);
      this._peerConnection = new this.PeerConnection(parsedOptions);

      // Add SPC trackAdded listener, which will take care all the tracks for all the subs.
      this.addTrackAddedListener();
    }

    this._subscriberPcs[subscriberPc._id] = [];

    return this._peerConnection;
  }

  removeMessageSender(subscriberPcId) {
    delete this._originalSendMessages[subscriberPcId];
    const originalSenders = Object.values(this._originalSendMessages);
    this._sendMessage = originalSenders[originalSenders.length - 1] || function () {};
  }

  removeSubscriber(subscriberPcId, transceivers) {
    this.removeMessageSender(subscriberPcId);
    this._peerConnection.stopTransceivers(transceivers);
  }

  removeFromSinglePeerConnection(subscriberPcId) {
    delete this._subscriberPcs[subscriberPcId];

    if (!Object.keys(this._subscriberPcs).length) {
      // Reset all components since we have no subscriber left. Then return,
      // nothing else to do here.
      this.reset();
      return;
    }

    if (this._firstSubscriberPc === subscriberPcId) {
      this._peerConnection.iceRestart();
      this._peerConnection.generateOfferAndSend();
      this._firstSubscriberPc = null;
    } else {
      const createAnswer = (offer) => {
        this.cachedSdp = SDPHelpers.updateSDPWithNewOffer(this.cachedSdp, offer.sdp);
        const answer = SDPHelpers.createSDP(this.cachedSdp.headers, this.cachedSdp.trackSections);
        // Since this answer is an actual mock of a Mantis answer, we add an extra flag so we avoid
        // to treat this message as a real answer once received.
        this._peerConnection.processMessage('answer', { content: { sdp: answer, mocked: true } });
      };
      this._peerConnection.generateOfferAndAnswer(createAnswer);
    }
  }

  removeTrack(track, subscriberPcId) {
    const trackId = track?.id;
    if (trackId) {
      arrayRemoveItem(this._tracks, trackId);
      arrayRemoveItem(this._subscriberPcs[subscriberPcId] || [], trackId);
    }
  }

  reset() {
    this._originalSendMessages = {};
    this._tracks = [];
    this._subscriberPcs = {};
    if (this._peerConnection) {
      this._peerConnection.disconnect();
      this._peerConnection = null;
    }
  }

  destroy() {
    this.reset();
    this._session = null;
  }

  addSubscriber(subscriberCreate) {
    this._subscriberCreateQueue.add(subscriberCreate);
  }

  subscriberComplete() {
    this._subscriberCreateQueue.next();
  }

  cacheOfferAnswer(content) {
    // We only cache Mantis offer/answer. The message will contain a mocked flag in case it was
    // created by us.
    if (!content.mocked) {
      this.cachedSdp = SDPHelpers.parseMantisSDP(content.sdp);
    }
  }
}

export default SinglePeerConnectionController;
