// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-void, one-var, prefer-const, no-shadow, vars-on-top, no-var */
/* eslint-disable no-mixed-operators */
import assign from 'lodash/assign';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import createLogger from '../../helpers/log';

const logging = createLogger('SDPHelpers');

const START_MEDIA_SSRC = 10000;
const START_RTX_SSRC = 20000;

// Here are the structure of the rtpmap attribute and the media line, most of the
// complex Regular Expressions in this code are matching against one of these two
// formats:
// * a=rtpmap:<payload type> <encoding name>/<clock rate> [/<encoding parameters>]
// * m=<media> <port>/<number of ports> <proto> <fmts>
//
// References:
// * https://tools.ietf.org/html/rfc4566
// * http://en.wikipedia.org/wiki/Session_Description_Protocol
//
//

const SDPHelpers = {
  getSections(sdp) {
    return sdp.split(/\r\n|\r|\n/).reduce((accum, line) => {
      const match = line.match(/^m=(\w+) \d+/);
      if (match) {
        accum.sections[accum.section = match[1]] = []; // eslint-disable-line no-param-reassign
      }
      accum.sections[accum.section].push(line);
      return accum;
    }, { sections: { header: [] }, section: 'header' }).sections;
  },
  getCodecsFromSection(section) {
    const codecs = section[0].match(/m=\w+ \d+ [A-Z/]+ ([\d ]+)$/)[1].split(' ');

    const codecMap = assign(...section
      .filter(line => line.match(/^a=rtpmap:\d+/))
      .map(line => line.match(/^a=rtpmap:(\d+) ([\w-]+)/).splice(1))
      .map(([num, codec]) => ({ [num]: codec })));

    return { codecs, codecMap };
  },
  getCodecsAndCodecMap(sdp, mediaType) {
    const section = SDPHelpers.getSections(sdp)[mediaType];

    if (!section) {
      throw new Error(`no mediaType ${mediaType}`);
    }
    return SDPHelpers.getCodecsFromSection(section);
  },
  getCodecs(sdp, mediaType) {
    const codecsAndCodecMap = SDPHelpers.getCodecsAndCodecMap(sdp, mediaType);
    return codecsAndCodecMap.codecs.map(num => codecsAndCodecMap.codecMap[num] || 'Unknown codec');
  },
  mediaDirections: {
    INACTIVE: 'inactive',
    RECVONLY: 'recvonly',
    SENDONLY: 'sendonly',
    SENDRECV: 'sendrecv',
  },
};

// Search through sdpLines to find the Media Line of type +mediaType+.
SDPHelpers.getMLineIndex = function getMLineIndex(sdpLines, mediaType) {
  const targetMLine = `m=${mediaType}`;

  // Find the index of the media line for +type+
  return findIndex(sdpLines, (line) => {
    if (line.indexOf(targetMLine) !== -1) {
      return true;
    }

    return false;
  });
};

// Grab a M line of a particular +mediaType+ from sdpLines.
SDPHelpers.getMLine = function getMLine(sdpLines, mediaType) {
  const mLineIndex = SDPHelpers.getMLineIndex(sdpLines, mediaType);
  return mLineIndex > -1 ? sdpLines[mLineIndex] : void 0;
};

SDPHelpers.hasMediaType = (sdp, mediaType) => {
  const mLineRegex = new RegExp(`^m=${mediaType}`);
  const sdpLines = sdp.split('\r\n');
  return findIndex(sdpLines, line => mLineRegex.test(line)) >= 0;
};

SDPHelpers.hasMLinePayloadType = function hasMLinePayloadType(sdpLines, mediaType, payloadType) {
  const mLine = SDPHelpers.getMLine(sdpLines, mediaType);
  const payloadTypes = SDPHelpers.getMLinePayloadTypes(mLine, mediaType);

  return payloadTypes.indexOf(payloadType) > -1;
};

// Extract the payload types for a give Media Line.
//
SDPHelpers.getMLinePayloadTypes = function getMLinePayloadTypes(mediaLine, mediaType) {
  const mLineSelector = new RegExp(`^m=${mediaType
  } \\d+(/\\d+)? [a-zA-Z0-9/]+(( [a-zA-Z0-9/]+)+)$`, 'i');

  // Get all payload types that the line supports
  const payloadTypes = mediaLine.match(mLineSelector);
  if (!payloadTypes || payloadTypes.length < 2) {
    // Error, invalid M line?
    return [];
  }

  return payloadTypes[2].trim().split(' ');
};

// Splits SDP into sessionpart and mediasections. Ensures CRLF.
SDPHelpers.splitSections = function splitSections(sdp) {
  const parts = sdp.split('\nm=');
  // eslint-disable-next-line prefer-template
  return parts.map((part, index) => (index > 0 ? 'm=' + part : part).trim() + '\r\n');
};

// Changes the media direction
SDPHelpers.changeMediaDirection = function changeMediaDirection(sdp, newDirection) {
  const replaceDirection = (mediaSdpPart) => {
    const currentDirection = newDirection === SDPHelpers.mediaDirections.RECVONLY ? 'a=inactive' : 'a=recvonly';
    const regex = new RegExp(currentDirection, 'g');
    return mediaSdpPart.replace(regex, `a=${newDirection}`);
  };

  // Splits SDP into session part and media sections.
  const sections = SDPHelpers.splitSections(sdp);

  // Preserve the session part since we don't need to modify it.
  const sessionSdpPart = sections.shift();
  let newSdp = sessionSdpPart;

  // Replace all the recvonly by inactive or viceversa, depending the active argument
  sections.forEach((section) => {
    // Only video is set to inactive/recvonly
    const mediaSection = section.includes('m=video') ? replaceDirection(section) : section;
    newSdp += mediaSection;
  });

  // Return the session and new media sections concatenated
  return newSdp;
};

SDPHelpers.removeTypesFromMLine = function removeTypesFromMLine(mediaLine, payloadTypes) {
  const typesSuffix = /[0-9 ]*$/.exec(mediaLine)[0];

  const newTypes = typesSuffix.split(' ').filter(type => type !== '' && payloadTypes.indexOf(type) === -1);

  return mediaLine.replace(typesSuffix, ` ${newTypes.join(' ')}`);
};

// Remove all references to a particular encodingName from a particular media type
//
SDPHelpers.removeMediaEncoding = function removeMediaEncoding(sdp, mediaType, encodingName) {
  let payloadTypes,
    i,
    j,
    parts;
  let sdpLines = sdp.split('\r\n');
  const mLineIndex = SDPHelpers.getMLineIndex(sdpLines, mediaType);
  const mLine = mLineIndex > -1 ? sdpLines[mLineIndex] : void 0;
  const typesToRemove = [];

  if (mLineIndex === -1) {
    // Error, missing M line
    return sdpLines.join('\r\n');
  }

  // Get all payload types that the line supports
  payloadTypes = SDPHelpers.getMLinePayloadTypes(mLine, mediaType);
  if (payloadTypes.length === 0) {
    // Error, invalid M line?
    return sdpLines.join('\r\n');
  }

  // Find the payloadTypes of the codecs.
  // Allows multiple matches e.g. for CN.
  for (i = mLineIndex; i < sdpLines.length; i++) {
    const codecRegex = new RegExp(encodingName, 'i');
    if (sdpLines[i].indexOf('a=rtpmap:') === 0) {
      parts = sdpLines[i].split(' ');
      if (parts.length === 2 && codecRegex.test(parts[1])) {
        typesToRemove.push(parts[0].substr(9));
      }
    }
  }

  if (!typesToRemove.length) {
    // Not found.
    return sdpLines.join('\r\n');
  }

  // Also find any rtx which reference the removed codec.
  for (i = mLineIndex; i < sdpLines.length; i++) {
    if (sdpLines[i].indexOf('a=fmtp:') === 0) {
      parts = sdpLines[i].split(' ');
      for (j = 0; j < typesToRemove.length; j++) {
        if (parts.length === 2 && parts[1] === `apt=${typesToRemove[j]}`) {
          typesToRemove.push(parts[0].substr(7));
        }
      }
    }
  }

  // Remove any rtpmap, fmtp or rtcp-fb.
  sdpLines = sdpLines.filter((line) => {
    for (let i = 0; i < typesToRemove.length; i++) {
      if (line.indexOf(`a=rtpmap:${typesToRemove[i]} `) === 0 ||
          line.indexOf(`a=fmtp:${typesToRemove[i]} `) === 0 ||
          line.indexOf(`a=rtcp-fb:${typesToRemove[i]} `) === 0) {
        return false;
      }
    }
    return true;
  });

  if (typesToRemove.length > 0 && mLineIndex > -1) {
    // Remove all the payload types and we've removed from the media line
    sdpLines[mLineIndex] = SDPHelpers.removeTypesFromMLine(mLine, typesToRemove);
  }

  return sdpLines.join('\r\n');
};

SDPHelpers.disableMediaType = function disableMediaType(sdp, mediaType) {
  const lines = sdp.split('\r\n');

  const blocks = [];
  let block;

  // Separating SDP into blocks. This usually follows the form:
  // Header block:
  //   v=0
  //   ...
  // Audio block:
  //   m=audio
  //   ...
  // Video block:
  //   m=video
  //   ...

  lines.forEach((lineParam) => {
    let line = lineParam;

    if (/^m=/.test(line)) {
      block = undefined;
    }

    if (!block) {
      block = [];
      blocks.push(block);
    }

    block.push(line);
  });

  // Now disable the block for the specified media type

  const mLineRegex = new RegExp(`^m=${mediaType} \\d+ ([^ ]+) [0-9 ]+$`);

  const fixedBlocks = blocks.map((block) => {
    const match = block[0].match(mLineRegex);

    if (match) {
      return [
        `m=${mediaType} 0 ${match[1]} 0`,
        'a=inactive',
        ...block.filter(line =>
          /^c=/.test(line) ||
          /^a=mid:/.test(line) ||
          line === '' // This preserves the trailing newline
        ),
      ];
    }

    return block;
  });

  return [].concat(...fixedBlocks).join('\r\n');
};

SDPHelpers.removeVideoCodec = function removeVideoCodec(sdp, codec) {
  return SDPHelpers.removeMediaEncoding(sdp, 'video', codec);
};

// Used to identify whether Video media (for a given set of SDP) supports
// retransmissions.
//
// The algorithm to do could be summarised as:
//
// IF ssrc-group:FID exists AND IT HAS AT LEAST TWO IDS THEN
//    we are using RTX
// ELSE IF "a=rtpmap: (\\d+):rtxPayloadId(/\\d+)? rtx/90000"
//          AND SDPHelpers.hasMLinePayloadType(sdpLines, 'Video', rtxPayloadId)
//    we are using RTX
// ELSE
//    we are not using RTX
//
// The ELSE IF clause basically covers the case where ssrc-group:FID
// is probably malformed or missing. In that case we verify whether
// we want RTX by looking at whether it's mentioned in the video
// media line instead.
//
const isUsingRTX = function isUsingRTX(sdpLines, videoAttrs) {
  let groupFID = videoAttrs.filterByName('ssrc-group:FID');
  const missingFID = groupFID.length === 0;

  if (!missingFID) {
    groupFID = groupFID[0].value.split(' ');
  } else {
    groupFID = [];
  }

  switch (groupFID.length) {
    case 0:
    case 1:
      // possibly no RTX, double check for the RTX payload type and that
      // the Video Media line contains that payload type
      //
      // Details: Look for a rtpmap line for rtx/90000
      //  If there is one, grab the payload ID for rtx
      //    Look to see if that payload ID is listed under the payload types for the m=Video line
      //      If it is: RTX
      //  else: No RTX for you

      var rtxAttr = videoAttrs.find(attr => attr.name.indexOf('rtpmap:') === 0 &&
               attr.value.indexOf('rtx/90000') > -1);

      if (!rtxAttr) {
        return false;
      }

      var rtxPayloadId = rtxAttr.name.split(':')[1];
      if (rtxPayloadId.indexOf('/') > -1) { rtxPayloadId = rtxPayloadId.split('/')[0]; }
      return SDPHelpers.hasMLinePayloadType(sdpLines, 'video', rtxPayloadId);

    default:
      // two or more: definitely RTX
      logging.debug('SDP Helpers: There are more than two FIDs, RTX is definitely enabled');
      return true;
  }
};

// This returns an Array, which is decorated with several
// SDP specific helper methods.
//
SDPHelpers.getAttributesForMediaType = function getAttributesForMediaType(sdpLines, mediaType) {
  let ssrcStartIndex,
    ssrcEndIndex,
    regResult,
    ssrc,
    ssrcGroup,
    ssrcLine,
    msidMatch,
    msid,
    mid,
    midIndex;
  const mLineIndex = SDPHelpers.getMLineIndex(sdpLines, mediaType);
  const matchOtherMLines = new RegExp(`m=(?!${mediaType}).+ `, 'i');
  const matchSSRCLines = new RegExp('a=ssrc:\\d+ .*', 'i');
  const matchSSRCGroup = new RegExp('a=ssrc-group:(SIM|FID) (\\d+).*?', 'i');
  const matchAttrLine = new RegExp('a=([a-z0-9:/-]+) (.*)', 'i');
  const attrs = [];

  for (let i = mLineIndex + 1; i < sdpLines.length; i++) {
    // If we were inside an SSRC block and we've now reached an m-line, then
    // tag the previous line as the end of the SSRC block
    if (matchOtherMLines.test(sdpLines[i])) {
      if (ssrcStartIndex !== undefined) {
        ssrcEndIndex = i - 1;
      }

      break;
    }

    // Get the ssrc
    ssrcGroup = sdpLines[i].match(matchSSRCGroup);
    ssrcLine = sdpLines[i].match(matchSSRCLines);

    if (ssrcStartIndex === undefined && (ssrcGroup || ssrcLine)) {
      // We found the start of an SSRC block
      ssrcStartIndex = i;

      if (ssrcGroup) {
        ssrc = /^(FID|SIM)$/.test(ssrcGroup[1]) ? ssrcGroup[2] : ssrcGroup[1];
      }
    }

    // Get the msid
    msidMatch = sdpLines[i].match(`a=ssrc:${ssrc} msid:(.+)`);
    if (msidMatch) {
      msid = msidMatch[1];
    }

    // find where the ssrc lines end
    const isSSRCLine = matchSSRCLines.test(sdpLines[i]);
    const isSSRCGroup = matchSSRCGroup.test(sdpLines[i]);

    if (ssrcStartIndex !== undefined) {
      if (ssrcEndIndex === undefined && !isSSRCLine && !isSSRCGroup) {
        // If we were inside an SSRC block and we've now reached a non-SSRC
        // line, then tag the previous line as the end of the SSRC block
        ssrcEndIndex = i - 1;
      } else if (i === sdpLines.length - 1) {
        // If we were inside an SSRC block and we've now reached the end of the
        // offer, tag the last line as the end of the SSRC block
        ssrcEndIndex = i;
      }
    }

    const midMatch = sdpLines[i].match(/a=mid:(.+)/);
    if (midMatch) {
      mid = midMatch[1];
      midIndex = i;
    }

    regResult = matchAttrLine.exec(sdpLines[i]);
    if (regResult && regResult.length === 3) {
      attrs.push({
        lineIndex: i,
        name: regResult[1],
        value: regResult[2],
      });
    }
  }

  // / The next section decorates the attributes array
  // / with some useful helpers.

  // Store references to the start and end indices
  // of the media section for this mediaType
  attrs.ssrcStartIndex = ssrcStartIndex;
  attrs.ssrcEndIndex = ssrcEndIndex;
  attrs.msid = msid;

  attrs.mid = mid;
  attrs.midIndex = midIndex;

  attrs.isUsingRTX = isUsingRTX.bind(null, sdpLines, attrs);

  attrs.filterByName = function filterByName(name) {
    return this.filter(attr => attr.name === name);
  };

  attrs.getRtpNumber = (mediaEncoding) => {
    const namePattern = new RegExp('rtpmap:(.+)');

    return find(attrs.map((attr) => {
      const nameMatch = attr.name.match(namePattern);
      if (nameMatch && attr.value.indexOf(mediaEncoding) >= 0) {
        return nameMatch[1];
      }
      return null;
    }), attr => attr !== null);
  };

  return attrs;
};

SDPHelpers.modifyDtx = (sdp, enable) => {
  const sdpLines = sdp.split('\r\n');
  if (!SDPHelpers.getMLine(sdpLines, 'audio')) {
    logging.debug('No audio m-line, not enabling dtx.');
    return sdp;
  }
  const audioAttrs = SDPHelpers.getAttributesForMediaType(sdpLines, 'audio');

  const rtpNumber = audioAttrs.getRtpNumber('opus');
  if (!rtpNumber) {
    logging.debug('Could not find rtp number for opus, not enabling dtx.');
    return sdp;
  }

  const fmtpAttr = audioAttrs.find(attr => attr.name === `fmtp:${rtpNumber}`);

  if (!fmtpAttr) {
    logging.debug('Could not find a=fmtp line for opus, not enabling dtx.');
    return sdp;
  }

  let line = sdpLines[fmtpAttr.lineIndex];
  const pattern = /usedtx=\d+([\s;]*)/;
  if (pattern.test(fmtpAttr.value)) {
    line = line.replace(pattern, enable ? 'usedtx=1$1' : '');
  } else if (enable) {
    line += '; usedtx=1';
  }

  // Trim any trailing whitespace and semicolons
  line = line.replace(/[;\s]*$/, '');

  sdpLines[fmtpAttr.lineIndex] = line;

  return sdpLines.join('\r\n');
};

const modifyStereo = (type, sdp, enable) => {
  const sdpLines = sdp.split('\r\n');
  if (!SDPHelpers.getMLine(sdpLines, 'audio')) {
    logging.debug('No audio m-line, not enabling stereo.');
    return sdp;
  }
  const audioAttrs = SDPHelpers.getAttributesForMediaType(sdpLines, 'audio');

  const rtpNumber = audioAttrs.getRtpNumber('opus');
  if (!rtpNumber) {
    logging.debug('Could not find rtp number for opus, not enabling stereo.');
    return sdp;
  }

  const fmtpAttr = audioAttrs.find(attr => attr.name === `fmtp:${rtpNumber}`);

  if (!fmtpAttr) {
    logging.debug('Could not find a=fmtp line for opus, not enabling stereo.');
    return sdp;
  }

  let line = sdpLines[fmtpAttr.lineIndex];
  let pattern;

  switch (type) {
    case 'send':
      pattern = /sprop-stereo=\d+([\s;]*)/;
      if (pattern.test(fmtpAttr.value)) {
        line = line.replace(pattern, enable ? 'sprop-stereo=1$1' : '');
      } else if (enable) {
        line += '; sprop-stereo=1';
      }
      break;

    case 'receive':
      pattern = /([^-])stereo=\d+([\s;]*)/;
      if (pattern.test(fmtpAttr.value)) {
        line = line.replace(pattern, enable ? '$1stereo=1$2' : '$1');
      } else if (enable) {
        line += '; stereo=1';
      }
      break;

    default:
      throw new Error(`Invalid type ${type} passed into enableStereo`);
  }

  // Trim any trailing whitespace and semicolons
  line = line.replace(/[;\s]*$/, '');

  sdpLines[fmtpAttr.lineIndex] = line;

  return sdpLines.join('\r\n');
};

SDPHelpers.modifySendStereo = modifyStereo.bind(null, 'send');
SDPHelpers.modifyReceiveStereo = modifyStereo.bind(null, 'receive');

SDPHelpers.setAudioBitrate = (sdp, audioBitrate) => {
  const existingValue = SDPHelpers.getAudioBitrate(sdp);
  if (existingValue !== undefined) {
    logging.debug(`Audio bitrate already set to ${existingValue}, not setting audio bitrate`);
    return sdp;
  }

  const sdpLines = sdp.split('\r\n');
  if (!SDPHelpers.getMLine(sdpLines, 'audio')) {
    logging.debug('No audio m-line, not setting audio bitrate');
    return sdp;
  }
  const audioAttrs = SDPHelpers.getAttributesForMediaType(sdpLines, 'audio');

  const rtpNumber = audioAttrs.getRtpNumber('opus');
  if (!rtpNumber) {
    logging.debug('Could not find rtp number for opus, not setting audio bitrate.');
    return sdp;
  }

  const fmtpAttr = audioAttrs.find(attr => attr.name === `fmtp:${rtpNumber}`);

  if (!fmtpAttr) {
    logging.debug('Could not find a=fmtp line for opus, not setting audio bitrate.');
    return sdp;
  }

  let line = sdpLines[fmtpAttr.lineIndex];
  line += `; maxaveragebitrate=${audioBitrate}`;

  // Trim any trailing whitespace and semicolons
  line = line.replace(/[;\s]*$/, '');

  sdpLines[fmtpAttr.lineIndex] = line;

  return sdpLines.join('\r\n');
};

SDPHelpers.removeVideoOrientation = (sdp) => {
  let sdpLines = sdp.split('\r\n');

  // We don't bother to limit this filter to only the video m-section since
  // this attribute isn't applicable to audio.
  sdpLines = sdpLines.filter(line => !line.includes('urn:3gpp:video-orientation'));

  return sdpLines.join('\r\n');
};

SDPHelpers.hasSendStereo = sdp => /[\s;]sprop-stereo=1/.test(sdp);
SDPHelpers.hasSendDtx = sdp => /[\s;]usedtx=1/.test(sdp);

SDPHelpers.getAudioBitrate = (sdp) => {
  const result = sdp.match(/[\s;]maxaveragebitrate=(\d+)/);
  if (result) {
    return Number(result[1]);
  }
  return undefined;
};

// Safety limit of unique media SSRCs allowed in an SDP offer
SDPHelpers.MAX_SSRCS = 10;

SDPHelpers.getSSRCGroupType = (videoAttrs) => {
  const TYPES = ['SIM', 'FID'];

  // Note: this returns `undefined` if SDP has neither type in `TYPES`
  return TYPES.find((type) => {
    const [exists] = videoAttrs.filterByName(`ssrc-group:${type}`);

    return exists;
  });
};

SDPHelpers.getSSRCGroup = (videoAttrs) => {
  const ssrcGroupType = SDPHelpers.getSSRCGroupType(videoAttrs);

  // The first group takes precedence
  const [ssrcGroup] = videoAttrs.filterByName(`ssrc-group:${ssrcGroupType}`);

  return ssrcGroup;
};

SDPHelpers.getSSRCGroupSSRCs = (videoAttrs) => {
  const ssrcGroup = SDPHelpers.getSSRCGroup(videoAttrs);

  // If no group type was found, then there's nothing to filter
  if (!ssrcGroup) {
    return [];
  }

  return ssrcGroup.value.split(' ').slice(0, SDPHelpers.MAX_SSRCS);
};

SDPHelpers.getAllowedSSRCs = (videoAttrs, ssrcGroupSSRCs) => {
  // All SSRCs in the ssrc-group are allowed
  const allowedSSRCs = ssrcGroupSSRCs.slice();

  // We need to allow the SSRCs that belong to the same FID group
  videoAttrs.filterByName('ssrc-group:FID').forEach((fid) => {
    const ssrcs = fid.value.split(' ');
    const isAllowed = intersection(allowedSSRCs, ssrcs).length;

    if (isAllowed) {
      Array.prototype.push.apply(allowedSSRCs, ssrcs);
    }
  });

  return uniq(allowedSSRCs);
};

SDPHelpers.filterExcessSSRCs = (sdp) => {
  const sdpLines = sdp.split('\r\n');
  const videoAttrs = SDPHelpers.getAttributesForMediaType(sdpLines, 'video');
  const ssrcGroupType = SDPHelpers.getSSRCGroupType(videoAttrs);
  const ssrcGroupIndex = videoAttrs.ssrcStartIndex;

  // This is only possible in an audio-only session
  if (ssrcGroupIndex === undefined) {
    return sdp;
  }

  let allowedSSRCs;

  // Returns the SSRC ID from the SDP line if it exists, otherwise returns null
  const getSSRCid = (line) => {
    let ssrc = null;
    if (line.match(/^a=ssrc:(\d+)/)) {
      ssrc = RegExp.$1;
    }
    return ssrc;
  };

  if (ssrcGroupType) {
    // Update ssrc-group with allowed SSRCs
    const ssrcGroupSSRCs = SDPHelpers.getSSRCGroupSSRCs(videoAttrs);
    const ssrcGroupLine = [`a=ssrc-group:${ssrcGroupType}`, ...ssrcGroupSSRCs].join(' ');

    const indexToReplace = videoAttrs.find(attr => attr.name.startsWith('ssrc-group:')).lineIndex;
    sdpLines[indexToReplace] = ssrcGroupLine;
    allowedSSRCs = SDPHelpers.getAllowedSSRCs(videoAttrs, ssrcGroupSSRCs);
  } else {
    // No ssrc-group was defined.  No SSRCs are allowed, so all isolated
    // (non-audio) SSRCs will be removed.
    allowedSSRCs = [];

    // We want to allow through a single, isolated, video ssrc which may occur in P2P sessions
    const hasIsolatedSSRCs = videoAttrs.ssrcStartIndex;
    if (hasIsolatedSSRCs) {
      // Parse the ssrc ID
      allowedSSRCs.push(getSSRCid(sdpLines[videoAttrs.ssrcStartIndex]));
    }
  }

  // Cleans up the SDP, by removing all SSRC-related lines that aren't allowed
  const filteredSdpLines = sdpLines.filter((line, index) => {
    // Passthrough lines outside the SSRC block
    if (index < ssrcGroupIndex || index > videoAttrs.ssrcEndIndex) {
      return true;
    }

    if (index === ssrcGroupIndex) {
      if (!ssrcGroupType) {
        const ssrcID = getSSRCid(line);
        if (ssrcID) {
          return allowedSSRCs.includes(ssrcID);
        }
        // Filter out isolated SSRCs, since no ssrc-group was found
        return !/^a=ssrc:(\d+)/.test(line);
      }

      return true;
    }

    // Make sure SSRC is allowed
    const ssrcID = getSSRCid(line);
    if (ssrcID) {
      return allowedSSRCs.includes(ssrcID);
    }

    // Make sure SSRCs in FID are allowed
    if (line.match(/^a=ssrc-group:FID (\d+)/)) {
      const ssrc = RegExp.$1;

      return allowedSSRCs.includes(ssrc);
    }

    // Avoid adding more than one sim group
    return !line.match(/^a=ssrc-group:SIM /);
  });

  return filteredSdpLines.join('\r\n');
};

// Modifies +sdp+ to enable Simulcast for +numberOfStreams+.
//
// Ok, here's the plan:
//  - add the 'a=ssrc-group:SIM' line, it will have numberOfStreams ssrcs
//  - if RTX then add one 'a=ssrc-group:FID', we need to add numberOfStreams lines
//  - add numberOfStreams 'a=ssrc:...' lines for the media ssrc
//  - if RTX then add numberOfStreams 'a=ssrc:...' lines for the RTX ssrc
//
// Re: media and rtx ssrcs:
// We just generate these. The Mantis folk would like us to use sequential numbers
// here for ease of debugging. We can use the same starting number each time as well.
// We should confirm with Oscar/Jose that whether we need to verify that the numbers
// that we choose don't clash with any other ones in the SDP.
//
// I think we do need to check but I can't remember.
//
// Re: The format of the 'a=ssrc:' lines
// Just use the following pattern:
//   a=ssrc:<Media or RTX SSRC> cname:localCname
//   a=ssrc:<Media or RTX SSRC> msid:<MSID>
//
// It doesn't matter that they are all the same and are static.
//
//
SDPHelpers.enableSimulcast = function enableSimulcast(sdp, numberOfStreams) {
  let linesToAdd,
    i;
  const sdpLines = sdp.split('\r\n');
  if (!SDPHelpers.getMLine(sdpLines, 'video')) {
    logging.debug('No video m-line, not enabling simulcast.');
    return sdp;
  }
  const videoAttrs = SDPHelpers.getAttributesForMediaType(sdpLines, 'video');

  if (videoAttrs.filterByName('ssrc-group:SIM').length > 0) {
    logging.debug('Simulcast is already enabled in this SDP, not attempting to enable again.');

    // We clean the SDP in case it somehow has SSRCs unrelated to the ssrc-group
    return SDPHelpers.filterExcessSSRCs(sdp);
  }

  if (!videoAttrs.msid) {
    logging.debug('No local stream attached, not enabling simulcast.');
    return sdp;
  }

  const usingRTX = videoAttrs.isUsingRTX();
  const mediaSSRC = [];
  const rtxSSRC = [];

  // generate new media (and rtx if needed) ssrcs
  for (i = 0; i < numberOfStreams; ++i) {
    mediaSSRC.push(START_MEDIA_SSRC + i);
    if (usingRTX) { rtxSSRC.push(START_RTX_SSRC + i); }
  }

  linesToAdd = [
    `a=ssrc-group:SIM ${mediaSSRC.join(' ')}`,
  ];

  if (usingRTX) {
    for (i = 0; i < numberOfStreams; ++i) {
      linesToAdd.push(`a=ssrc-group:FID ${mediaSSRC[i]} ${rtxSSRC[i]}`);
    }
  }

  for (i = 0; i < numberOfStreams; ++i) {
    linesToAdd.push(`a=ssrc:${mediaSSRC[i]} cname:localCname`,
      `a=ssrc:${mediaSSRC[i]} msid:${videoAttrs.msid}`);
  }

  if (usingRTX) {
    for (i = 0; i < numberOfStreams; ++i) {
      linesToAdd.push(`a=ssrc:${rtxSSRC[i]} cname:localCname`,
        `a=ssrc:${rtxSSRC[i]} msid:${videoAttrs.msid}`);
    }
  }

  // Replace the previous video ssrc section with our new video ssrc section by
  // deleting the old ssrcs section and inserting the new lines
  linesToAdd.unshift(videoAttrs.ssrcStartIndex, videoAttrs.ssrcEndIndex -
    videoAttrs.ssrcStartIndex + 1);
  sdpLines.splice(...linesToAdd);

  return sdpLines.join('\r\n');
};

SDPHelpers.reprioritizeVideoCodec = function reprioritizeVideoCodec(sdp, codec, location) {
  const lines = sdp.split('\r\n');

  const mLineIndex = SDPHelpers.getMLineIndex(lines, 'video');

  if (mLineIndex === -1) {
    return sdp;
  }

  const payloadTypes = SDPHelpers.getMLinePayloadTypes(lines[mLineIndex], 'video');

  const regex = new RegExp(`^a=rtpmap:(\\d+).* ${codec}`, 'i');
  const codecMatches = lines.map(line => line.match(regex)).filter(match => match !== null);

  if (codecMatches.length === 0) {
    return sdp;
  }

  const codecTypeCodes = codecMatches.map(match => match[1]);

  let newPayloadTypes = payloadTypes.filter(t => codecTypeCodes.indexOf(t) === -1);

  if (location === 'top') {
    newPayloadTypes.unshift(...codecTypeCodes);
  } else if (location === 'bottom') {
    newPayloadTypes.push(...codecTypeCodes);
  } else {
    logging.error(`Unexpected location param: ${location}; not changing ${codec} priority`);
    newPayloadTypes = payloadTypes;
  }

  const newMLine = lines[mLineIndex].replace(payloadTypes.join(' '), newPayloadTypes.join(' '));
  lines[mLineIndex] = newMLine;

  return lines.join('\r\n');
};

SDPHelpers.getSetupRole = (sdp) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const line of sdp.split(/\r\n|\r|\n/)) {
    const match = line.match(/^a=setup:(\w+)/);
    if (match) {
      return match[1];
    }
  }
  return '';
};

SDPHelpers.setSetupRole = (sdp, setupRole) =>
  sdp.split(/\r\n|\r|\n/).map((line) => {
    if (line.match(/^a=setup:(\w+)/)) {
      return `a=setup:${setupRole}`;
    }
    return line;
  }).join('\r\n');

SDPHelpers.changeSetupRole = (sdp, oldSetupRole, newSetupRole) =>
  sdp.map(line => (line.includes(`a=setup:${oldSetupRole}`) ? `a=setup:${newSetupRole}` : line));

SDPHelpers.patterns = {
  // group sample: a=group:BUNDLE 0a 1v 2a 3v
  bundle: 'a=group:BUNDLE',
  // address sample: c=IN IP4 54.201.197.86
  address: 'c=IN IP4',
  // rtcp sample: a=rtcp:41511
  rtcp: 'a=rtcp:',
  // direction sample: a=sendonly
  direction: ['a=inactive', 'a=sendonly', 'a=recvonly', 'a=sendrecv'],
  // a=rtcp-rsize
  rsize: 'a=rtcp-rsize',
  // ssrc sample: a=ssrc:3 cname:1E7BE30B-C693-4570-B552-34244D6F04C1
  ssrc: 'a=ssrc:',
  // msid sample: a=msid:McwRQfjkv8dWA33jaqKHwuBh6ovY0TxNpBZB 3c18dd96-8af5-4e07-856d-0cd21e0203b8
  msid: 'a=msid:',
};

// Get index from first match starting at 'startIndex'
SDPHelpers.getIndexStartingWith = (sdpLines, patternList, startIndex) => {
  const slicedSdp = sdpLines.slice(startIndex);

  const matchIndexes = patternList
    .map(pattern => slicedSdp.findIndex(line => line.includes(pattern)))
    .filter(i => i > -1);

  if (!matchIndexes.length) {
    return sdpLines.length;
  }

  // Return the first index found that matches with any of patterns
  return startIndex + Math.min(...matchIndexes);
};

// Get index from last match before 'endIndex'
SDPHelpers.getLastIndexPriorTo = (sdpLines, pattern, endIndex) => {
  // Get subarray for the first {index} number of elements
  const slicedSdp = sdpLines.slice(0, endIndex);
  const sdpMap = slicedSdp.map(line => line.includes(pattern));
  return sdpMap.lastIndexOf(true);
};

// Like String.prototype.includes, except it works with an array of
// possible values
SDPHelpers.includesAny = (sdpLine, values) => {
  for (let i = 0; i < values.length; i++) {
    if (sdpLine.includes(values[i])) {
      return true;
    }
  }
  return false;
};

SDPHelpers.isVersionLine = line => /^o=/.test(line);

SDPHelpers.getVersionLine = sdpLines => sdpLines.find(SDPHelpers.isVersionLine);

SDPHelpers.getVersion = (sdpLines) => {
  const origin = SDPHelpers.getVersionLine(sdpLines);

  if (origin === undefined) {
    return undefined;
  }
  // For example: o=- 2356644873 4 IN IP4 34.194.94.104
  // Will return: 4
  const version = Number(origin.match(/ (\d+) IN /)[1]);

  return Number.isInteger(version) ? version : undefined;
};

SDPHelpers.updateVersion = (sdpLines, oldVersion, newVersion) => {
  const updatedSdpLines = [...sdpLines];
  const versionLineIndex = updatedSdpLines.findIndex(line => SDPHelpers.isVersionLine(line));
  updatedSdpLines[versionLineIndex] =
    updatedSdpLines[versionLineIndex].replace(` ${oldVersion} `, ` ${newVersion} `);
  return updatedSdpLines;
};

SDPHelpers.isBundleLine = line => line.includes(SDPHelpers.patterns.bundle);

SDPHelpers.getBundleLine = sdpLines => sdpLines.find(SDPHelpers.isBundleLine);

SDPHelpers.createBundleLine = tracks => `${SDPHelpers.patterns.bundle} ${tracks.join(' ')}`;

SDPHelpers.updateBundleLine = (sdpLines, tracks) => {
  const updatedSdpLines = [...sdpLines];
  const bundleLine = SDPHelpers.createBundleLine(tracks);
  const bundleLineIndex = updatedSdpLines.findIndex(line => SDPHelpers.isBundleLine(line));
  updatedSdpLines[bundleLineIndex] = bundleLine;
  return updatedSdpLines;
};

SDPHelpers.parseMLine = (mLine) => {
  // Given: m=audio 41511 RTP/SAVPF 111
  // Parser will return:
  // * mediaType: m=audio
  // * port: 41511
  // * transport (transport protocol and media format descriptions): RTP/SAVPF 111
  const [, mediaType, port, transport] = mLine.match(/^(m=(?:audio|video)) (\d+) (.+)/);

  return {
    port,
    transport,
    mediaType,
  };
};

SDPHelpers.disableTrack = (mLine) => {
  // To disable tracks, we change the port of the m-line to zero
  // For example, given the following audio track:
  //   m=audio 41511 RTP/SAVPF 111
  // Disabling it will transform it to:
  //   m=audio 0 RTP/SAVPF 111
  const { mediaType, transport } = SDPHelpers.parseMLine(mLine);

  return `${mediaType} 0 ${transport}`;
};

SDPHelpers.setMediaDirection = (_sdpLines, direction) => {
  const isValidDirection = Object.values(SDPHelpers.mediaDirections).includes(direction);

  if (!isValidDirection) {
    return _sdpLines;
  }

  const sdpLines = _sdpLines.slice();
  const directionIndex = sdpLines.findIndex(line =>
    SDPHelpers.includesAny(line, SDPHelpers.patterns.direction));

  if (directionIndex === -1) {
    // Oops, like like there's no direction attribute
    return sdpLines;
  }

  sdpLines[directionIndex] = `a=${direction}`;

  return sdpLines;
};

SDPHelpers.disableTrackSection = (section) => {
  const { mid, sdp } = section;

  // Remove lines for address, rtcp, rsize, ssrc and msid
  let disabledTrackSdp = sdp.filter((line) => {
    const { address, rtcp, rsize, ssrc, msid } = SDPHelpers.patterns;

    return !SDPHelpers.includesAny(line, [address, rtcp, rsize, ssrc, msid]);
  });

  // We always disable the track on the first line
  disabledTrackSdp[0] = SDPHelpers.disableTrack(sdp[0]);
  disabledTrackSdp = SDPHelpers.setMediaDirection(
    disabledTrackSdp, SDPHelpers.mediaDirections.INACTIVE);

  return { mid, sdp: disabledTrackSdp };
};

SDPHelpers.getEnabledTracks = (sdpLines) => {
  const bundleLine = SDPHelpers.getBundleLine(sdpLines);

  // Extract the list of tracks
  // So that 'a=group:BUNDLE 0a 1v 2a 3v', returns [0a, 1v, 2a, 3v]
  const tracks = bundleLine.match(new RegExp(`^${SDPHelpers.patterns.bundle} (.+)$`))[1];

  return tracks.split(' ');
};

SDPHelpers.getDisabledTracks = (currentTracks, newTracks) =>
  // Get tracks that were present in previous SDP but not in the new SDP
  currentTracks.filter(track => !newTracks.includes(track));

SDPHelpers.getTrackSection = (sdpLines, mid) => {
  const midIndex = sdpLines.findIndex(line => line.includes(`a=mid:${mid}`));

  // Recall that tracks are prefixed with integers
  // (e.g., 0a, 1a, for audio; and 2v, 3v for video)
  const mediaType = mid.includes('a') ? 'audio' : 'video';

  // e.g. m=audio 41511 RTP/SAVPF 111
  const trackStartIndex = SDPHelpers.getLastIndexPriorTo(sdpLines, `m=${mediaType}`, midIndex);
  // Find the next track start index
  const trackEndIndex = SDPHelpers.getIndexStartingWith(sdpLines, ['m=audio', 'm=video'], midIndex);
  const sectionSdp = sdpLines.slice(trackStartIndex, trackEndIndex);

  return { mid, sdp: sectionSdp };
};

SDPHelpers.getHeaders = (sdpLines) => {
  // Headers consist of SDP lines from the beginning to the first track section
  const indexFirstTrack = sdpLines.findIndex(line => line.includes('m=audio') || line.includes('m=video'));
  return sdpLines.slice(0, indexFirstTrack);
};

SDPHelpers.disableTracks = (sections, tracks) => sections.map(section =>
  (tracks.includes(section.mid) ? SDPHelpers.disableTrackSection(section) : section));

SDPHelpers.getSDPLines = sdp =>
  sdp
    .split('\r\n')
    // Remove empty strings, if any
    .filter(line => line);

SDPHelpers.getTrackMids = (sdpLines) => {
  const midPrefix = 'a=mid:';
  const midLines = sdpLines.filter(line => line.match(new RegExp(`^${midPrefix}\\d+`)));
  return midLines.map(line => line.substring(midPrefix.length));
};

SDPHelpers.getTrackSections = (sdpLines) => {
  const mids = SDPHelpers.getTrackMids(sdpLines);
  return mids.map(mid =>
    SDPHelpers.getTrackSection(sdpLines, mid));
};

SDPHelpers.parseMantisSDP = (sdp) => {
  const sdpLines = SDPHelpers.getSDPLines(sdp);
  // Mantis may set role to actpass, which can be incorrect. We play safe and set it to passive.
  const mungedSDP = SDPHelpers.changeSetupRole(sdpLines, 'actpass', 'passive');
  return SDPHelpers.parseSDP(mungedSDP);
};

SDPHelpers.parseSDP = (sdp) => {
  const sdpLines = Array.isArray(sdp) ? sdp : SDPHelpers.getSDPLines(sdp);

  const parsedSDP = {
    bundle: SDPHelpers.getBundleLine(sdpLines),
    headers: SDPHelpers.getHeaders(sdpLines),
    version: SDPHelpers.getVersion(sdpLines),
    tracks: SDPHelpers.getEnabledTracks(sdpLines),
    trackSections: SDPHelpers.getTrackSections(sdpLines),
  };

  return parsedSDP;
};

SDPHelpers.createSDP = (headers, trackSections) => {
  const sdp = [...headers];
  trackSections.forEach(section => sdp.push(...section.sdp));
  // Add empty line to the end of the SDP
  sdp.push('');
  return sdp.join('\r\n');
};

SDPHelpers.removePayloadTypeFromSection = (trackSection, payloadType) => trackSection.filter(line =>
  !line.includes(`a=rtpmap:${payloadType} `) &&
  !line.includes(`a=fmtp:${payloadType} `) &&
  !line.includes(`a=rtcp-fb:${payloadType} `));

SDPHelpers.getRtxPayloadType = (trackSection, payloadType) => {
  const rtxPayloadLine = trackSection.filter(line => line.includes(`apt=${payloadType}`))[0];
  const rtxPayloadType = rtxPayloadLine?.match(/^a=fmtp:(\d+) /)[1];
  return rtxPayloadType;
};

SDPHelpers.removeRtxPayloadTypeFromSection = (trackSection, payloadType) => {
  let trimmedSection = [...trackSection];
  // We look for rtx payload type associated with the current payload type, e.g.:
  // a=fmtp:97 apt=96;rtx-time=3000
  // We should get payload type 97 which is the rtx associated to payload type 96
  const rtxPayloadType = SDPHelpers.getRtxPayloadType(trimmedSection, payloadType);
  if (rtxPayloadType) {
    trimmedSection = SDPHelpers.removePayloadTypeFromSection(trimmedSection, rtxPayloadType);
  }
  return trimmedSection;
};

SDPHelpers.trimUnusedCodecs = (trackSection, usedCodecs) => {
  // Payload types are in the first line of the section, e.g.:
  // m=audio 41511 RTP/SAVPF 111 8 106 105
  // being the payload types: 111 8 106 105
  const currentPayloadLine = trackSection[0].split(' ');
  const payloadTypes = currentPayloadLine.slice(3);
  // We save m=audio 41511 RTP/SAVPF to build the line above
  const payloadLinePrefix = currentPayloadLine.slice(0, 3);

  const newCodecs = SDPHelpers.getCodecsFromSection(trackSection);

  let trimmedSection = [...trackSection];
  let trimmedPayloads = [];

  payloadTypes.forEach((payloadType) => {
    const codecName = newCodecs.codecMap[payloadType];

    if (!usedCodecs.includes(codecName)) {
      // Remove all codecs associated lines since this is not used
      trimmedSection = SDPHelpers.removePayloadTypeFromSection(trimmedSection, payloadType);
      // Remove rtx payload type associated if needed
      trimmedSection = SDPHelpers.removeRtxPayloadTypeFromSection(trimmedSection, payloadType);
    } else {
      // This payload type has been used before so we keep it
      trimmedPayloads.push(payloadType);
      const rtxPayloadType = SDPHelpers.getRtxPayloadType(trimmedSection, payloadType);
      if (rtxPayloadType) {
        trimmedPayloads.push(rtxPayloadType);
      }
    }
  });

  // If we are trimming a section previously zeroed by Mantis, this won't have any payload, thus
  // we need to add a 0 payload, because negotiation expects to have at least one.
  const trimmedPayloadsLine = trimmedPayloads.length ? trimmedPayloads : [0];
  // Replace the mLine with its proper payload types
  trimmedSection[0] = [...payloadLinePrefix, ...trimmedPayloadsLine].join(' ');

  return trimmedSection;
};

SDPHelpers.removeUnusedCodecs = (newOffer, previousOffer) => {
  if (!previousOffer) {
    return newOffer;
  }

  const { headers, trackSections } = SDPHelpers.parseSDP(newOffer);
  const previousTrackSections = SDPHelpers.parseSDP(previousOffer).trackSections;

  // We only want to keep using the already used codecs
  const trimmedTrackSections = trackSections.map((trackSection) => {
    const { mid, sdp } = trackSection;
    const previousTrackSection = previousTrackSections.find(section => section.mid === mid);
    if (!previousTrackSection) {
      return sdp;
    }
    // Array with all the codecs used for this track
    const usedCodecs =
      Object.values(SDPHelpers.getCodecsFromSection(previousTrackSection.sdp).codecMap);
    // Filter out all the codecs added by the browser
    return SDPHelpers.trimUnusedCodecs(sdp, usedCodecs);
  });

  // Build munged offer with all the unused codecs removed
  // Offer is based on headers, track sections and an empty end line
  const trimmedOffer = [...headers];
  trimmedTrackSections.forEach(section => trimmedOffer.push(...section));
  // New line
  trimmedOffer.push('');

  return trimmedOffer.join('\r\n');
};

SDPHelpers.updateSDPWithNewOffer = (previousSdp, newSdp) => {
  const { headers, version, tracks, trackSections } = previousSdp;
  const sdpLines = SDPHelpers.getSDPLines(newSdp);

  const sdp = {};

  // Get the latest version of the negotiation
  sdp.version = SDPHelpers.getVersion(sdpLines);
  sdp.headers = SDPHelpers.updateVersion(headers, version, sdp.version);

  sdp.tracks = SDPHelpers.getEnabledTracks(sdpLines);
  const disabledTracks = SDPHelpers.getDisabledTracks(tracks, sdp.tracks);

  sdp.headers = SDPHelpers.updateBundleLine(sdp.headers, sdp.tracks);

  sdp.trackSections = SDPHelpers.disableTracks(trackSections, disabledTracks);

  return sdp;
};

export default SDPHelpers;
