// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require, no-underscore-dangle, func-names */
import assign from 'lodash/assign';
import pick from 'lodash/pick';
import find from 'lodash/find';
import createAudioFallbackStateMachineDefault from './audioFallbackStateMachine';
import createCongestionLevelEstimatorDefault from './congestionLevel/congestionLevelEstimator';
import createNetworkQualityMonitorDefault from './congestionLevel/videoRecovery/networkQualityMonitor';
import createLogger from '../../helpers/log';
import eventing from '../../helpers/eventing';
import shouldForceTurn from '../../helpers/shouldForceTurn';
import setICEConfigWithForcedTurn from '../../helpers/setIceConfigWithForcedTurn';
import getMaxBitrateForResolution from './getMaxBitrateForResolution';
import getGetStatsHelpers from './get_stats_helpers';
import getOTHelpers from '../../common-js-helpers/OTHelpers';
import getPeerConnection from './peer_connection';
import getSetCertificates from './set_certificates';
import getWatchAudioAcquisition from './watchAudioAcquisition';
import { CongestionLevels } from './congestionLevel/congestionLevels';
import AudioFallbackVideoStates from '../publisher/audioFallbackVideoStates';
import getDelayForAmrProbeDefault from './congestionLevel/videoRecovery/getDelayForAmrProbe';

const { ACTIVE_VIDEO, SUSPENDED_VIDEO } = AudioFallbackVideoStates;

const logging = createLogger('PublisherPeerConnection');

export default function PublisherPeerConnectionFactory(deps = {}) {
  const getStatsHelpers = deps.getStatsHelpers || getGetStatsHelpers;
  const OTHelpers = deps.OTHelpers || getOTHelpers;
  const PeerConnection = deps.PeerConnection || getPeerConnection();
  const setCertificates = deps.setCertificates || getSetCertificates();
  const watchAudioAcquisition = deps.watchAudioAcquisition || getWatchAudioAcquisition;
  const createAudioFallbackStateMachine =
    deps.createAudioFallbackStateMachine || createAudioFallbackStateMachineDefault;
  const createCongestionLevelEstimator =
    deps.createCongestionLevelEstimator || createCongestionLevelEstimatorDefault;
  const createNetworkQualityMonitor =
    deps.createNetworkQualityMonitor || createNetworkQualityMonitorDefault;
  const getDelayForAmrProbe = deps.getDelayForAmrProbe || getDelayForAmrProbeDefault;

  /**
   * @typedef {object} PublisherPeerConnectionConfig
   * @property {function(string, string=, object=, object=, boolean=)} logAnalyticsEvent
   * @property {Connection} remoteConnection
   * @property {boolean} reconnection
   * @property {MediaStream} webRTCStream
   * @property {object<string, object>} channels
   * @property {boolean} capableSimulcastStreams
   * @property {boolean} overrideSimulcastEnabled
   * @property {string} subscriberUri
   * @property {object<string, object>} offerOverrides
   * @property {object<string, object>} answerOverrides
   * @property {string} sourceStreamId
   * @property {string} isP2pEnabled
   */

  /**
   * Abstracts PeerConnection related stuff away from Publisher.
   *
   * Responsible for:
   * * setting up the underlying PeerConnection (delegates to PeerConnections)
   * * triggering a connected event when the Peer connection is opened
   * * triggering a disconnected event when the Peer connection is closed
   * * providing a destroy method
   * * providing a processMessage method
   *
   * Once the PeerConnection is connected and the video element playing it triggers
   * the connected event
   *
   * Triggers the following events
   * * connected
   * * disconnected
   *
   * @class PublisherPeerConnection
   * @constructor
   *
   * @param {PublisherPeerConnectionConfig} config
   */
  return function PublisherPeerConnection({
    iceConfig,
    webRTCStream,
    channels,
    sendMessage,
    capableSimulcastStreams,
    overrideSimulcastEnabled,
    logAnalyticsEvent,
    offerOverrides,
    answerOverrides,
    sourceStreamId,
    isP2pEnabled,
    sessionId,
    keyStore,
    sFrameClientStore,
    isE2ee,
    audioFallbackEnabled,
  }) {
    let _peerConnection;
    let _awaitingIceRestart = false;
    let _cancelWatchAudioAcquisition;
    let audioFallbackStateMachine;
    let congestionLevelEstimator;
    let networkQualityMonitor;

    // Private
    const _onPeerClosed = function () {
      this.destroy();
      if (_awaitingIceRestart) {
        _awaitingIceRestart = false;
        this.trigger('iceRestartFailure');
      }
      this.trigger('disconnected');
    };

    const _onPeerError = function ({ reason, prefix }) {
      this.trigger('error', { reason, prefix });
    };

    const _onIceConnectionStateChange = function (state) {
      if (_awaitingIceRestart && _peerConnection.iceConnectionStateIsConnected()) {
        _awaitingIceRestart = false;
        this.trigger('iceRestartSuccess');
      }

      // watch for the Chrome bug where audio can't be acquired
      // can not use iceConnectionStateIsConnected since it is too broad
      if (state === 'connected') {
        if (OTHelpers.env.name === 'Chrome') {
          // cancel any pending watcher (in case of ice restart for example)
          if (_cancelWatchAudioAcquisition) {
            _cancelWatchAudioAcquisition();
          }
          _cancelWatchAudioAcquisition = watchAudioAcquisition(
            _peerConnection.getStats.bind(_peerConnection),
            () => this.trigger('audioAcquisitionProblem')
          );
        }
        this.trigger('connected');
      }

      this.trigger('iceConnectionStateChange', state);
    };

    this._setVideoTrackEncodingActiveState = async (isActive) => {
      const videoSender = this
        .getSenders()
        .find(({ track }) => track.kind === 'video');
      const parameters = videoSender.getParameters();
      parameters.encodings.forEach((encoding) => {
        // eslint-disable-next-line no-param-reassign
        encoding.active = isActive;
      });
      try {
        await videoSender.setParameters(parameters);
      } catch (e) {
        logging.error(e);
      }
    };

    eventing(this);

    // / Public API

    this.setCongestionLevel = (level) => {
      audioFallbackStateMachine?.setCongestionLevel(level);
    };

    this.getAudioFallbackState = () => audioFallbackStateMachine?.getState();

    this.startEncryption = (connectionId) => {
      if (_peerConnection) {
        _peerConnection.startEncryption(connectionId);
      }
    };

    this.changeMediaDirectionToInactive = () => {
      if (_peerConnection) {
        _peerConnection.changeMediaDirectionToInactive();
      }
    };

    this.changeMediaDirectionToRecvOnly = () => {
      if (_peerConnection) {
        _peerConnection.changeMediaDirectionToRecvOnly();
      }
    };

    this.getDataChannel = function (label, options, completion) {
      _peerConnection.getDataChannel(label, options, completion);
    };

    this.getSourceStreamId = () => _peerConnection.getSourceStreamId();

    this.destroy = function () {
      congestionLevelEstimator?.stop();
      networkQualityMonitor?.stop();
      if (_cancelWatchAudioAcquisition) {
        _cancelWatchAudioAcquisition();
        _cancelWatchAudioAcquisition = null;
      }

      // Clean up our PeerConnection
      if (_peerConnection) {
        _peerConnection.disconnect();
        _peerConnection = null;
      }

      this.off();
    };

    this.processMessage = function (type, message) {
      _peerConnection.processMessage(type, message);
    };

    this.addTrack = function (track, stream, callback) {
      return _peerConnection.addTrack(track, stream, callback);
    };

    this.removeTrack = function (RTCRtpSender) {
      return _peerConnection.removeTrack(RTCRtpSender);
    };

    this.getLocalStreams = function () {
      return _peerConnection.getLocalStreams();
    };

    // Init
    this.init = function (rumorIceServers, completion) {
      if (shouldForceTurn(sourceStreamId)) {
        setICEConfigWithForcedTurn(iceConfig);
      }
      const pcConfig = {
        iceConfig: !iceConfig.needRumorIceServersFallback ? iceConfig : assign(iceConfig, {
          servers: [...rumorIceServers, ...iceConfig.servers],
        }),
        channels,
        capableSimulcastStreams,
        overrideSimulcastEnabled,
      };

      setCertificates(pcConfig, (err, pcConfigWithCerts) => {
        if (err) {
          completion(err);
          return;
        }

        const peerConnectionConfig = assign(
          {
            logAnalyticsEvent,
            isPublisher: true,
            offerOverrides,
            answerOverrides,
            sourceStreamId,
            p2p: isP2pEnabled,
            sessionId,
            keyStore,
            sFrameClientStore,
            isE2ee,
          },
          pcConfigWithCerts
        );

        _peerConnection = new PeerConnection(
          assign({ sendMessage }, peerConnectionConfig)
        );

        _peerConnection.on({
          close: _onPeerClosed,
          error: _onPeerError,
          qos: qos => this.trigger('qos', qos),
          iceConnectionStateChange: _onIceConnectionStateChange,
        }, this);

        _peerConnection.addLocalStream(webRTCStream)
          .then(() => {
            completion(undefined);
          })
          .catch(completion);
      });
    };

    this.setIceConfig = (newIceConfig) => {
      _peerConnection.setIceConfig(newIceConfig);
    };

    this.generateOffer = () => {
      _peerConnection.generateOfferAndSend();
    };

    this.getSenders = function () {
      return _peerConnection.getSenders();
    };

    this.iceRestart = function () {
      if (_peerConnection) {
        _awaitingIceRestart = true;
        _peerConnection.iceRestart();
      }
    };

    this.hasRelayCandidates = () => _peerConnection.hasRelayCandidates();

    this.iceConnectionStateIsConnected = function () {
      return _peerConnection.iceConnectionStateIsConnected();
    };

    this.findAndReplaceTrack = (oldTrack, newTrack) => (
      _peerConnection.findAndReplaceTrack(oldTrack, newTrack)
    );

    this._testOnlyGetFramesEncoded = () => new Promise((resolve, reject) => {
      _peerConnection.getStats((err, stats) => {
        if (err) {
          reject(err);
          return;
        }

        const videoStat = find(stats, stat => (
          getStatsHelpers.isVideoStat(stat, stats) && getStatsHelpers.isOutboundStat(stat)
        ));

        if (!videoStat) {
          reject(new Error('Could not find framesEncoded in getStats report'));
          return;
        }

        resolve(pick(videoStat, ['timestamp', 'framesEncoded']));
      });
    });

    this.getStats = callback => _peerConnection.getStats(callback);

    this.getRtcStatsReport = callback => _peerConnection.getRtcStatsReport(callback);

    this.setVideoActiveState = (isActive) => {
      this._setVideoTrackEncodingActiveState(isActive);
      if (audioFallbackEnabled) {
        if (isActive) {
          this.enableCongestionLevelEstimation();
        } else {
          this.disableCongestionLevelEstimation();
        }
      }
    };

    this.setP2PMaxBitrate = async () => {
      if (this.getSourceStreamId() !== 'P2P') return;

      const sender = this.getSenders().find(({ track: { kind } }) => kind === 'video');
      if (!sender) return;

      const { width, height } = sender?.track?.getSettings?.() || {};

      const maxBitrate = getMaxBitrateForResolution(width, height);
      const sendParameters = sender.getParameters();
      sendParameters.encodings.forEach((encoding) => {
        encoding.maxBitrate = maxBitrate; // eslint-disable-line no-param-reassign
      });
      try {
        await sender.setParameters(sendParameters);
      } catch (e) {
        // ignore error
      }
    };

    const onSuspendedVideo = ({ isAmrTransition = false } = {}) => {
      this._setVideoTrackEncodingActiveState(false);
      congestionLevelEstimator?.stop();
      networkQualityMonitor?.start(
        isAmrTransition ? { getDelayForProbeNumber: getDelayForAmrProbe } : {}
      );
    };

    this.disableCongestionLevelEstimation = () => {
      if (audioFallbackEnabled) {
        congestionLevelEstimator?.stop();
        networkQualityMonitor?.stop();
        audioFallbackStateMachine?.reset();
      }
    };

    this.enableCongestionLevelEstimation = (amrAudioFallbackState) => {
      if (audioFallbackEnabled) {
        networkQualityMonitor?.stop();
        if (amrAudioFallbackState) {
          audioFallbackStateMachine.setInternalState(amrAudioFallbackState);
          if (amrAudioFallbackState.videoState === SUSPENDED_VIDEO) {
            onSuspendedVideo({ isAmrTransition: true });
            return;
          }
        } else {
          audioFallbackStateMachine.setCongestionLevel(CongestionLevels.LOW);
        }
        congestionLevelEstimator?.start();
      }
    };

    const initAudioFallbackStateMachine = () => {
      audioFallbackStateMachine = createAudioFallbackStateMachine();
      audioFallbackStateMachine.on('stateChange', ({ state }) => {
        switch (state) {
          case ACTIVE_VIDEO:
            this._setVideoTrackEncodingActiveState(true);
            break;
          case SUSPENDED_VIDEO:
            onSuspendedVideo();
            break;
          default:
            break;
        }
        this.trigger('audioFallbackStateChange', ({
          state,
        }));
      });
      congestionLevelEstimator.on('congestionLevel', (level) => {
        audioFallbackStateMachine?.setCongestionLevel(level);
      });
      networkQualityMonitor.on('networkRecovered', () => {
        audioFallbackStateMachine?.setCongestionLevel(CongestionLevels.LOW);
        congestionLevelEstimator?.start();
      });
    };

    const initCongestionLevelEstimator = () => {
      congestionLevelEstimator = createCongestionLevelEstimator(
        { getStats: this.getStats });
      this.on('connected', () => {
        congestionLevelEstimator.start();
      });
    };

    const initVideoRecoveryProbeCoordinator = () => {
      networkQualityMonitor = createNetworkQualityMonitor({
        getStats: this.getStats,
        setVideoActive: this._setVideoTrackEncodingActiveState,
      });
    };

    if (audioFallbackEnabled) {
      initVideoRecoveryProbeCoordinator();
      initCongestionLevelEstimator();
      initAudioFallbackStateMachine();
    }
  };
}
